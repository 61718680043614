import React from 'react'
import { motion } from 'framer-motion';
import { AppWrap } from '../../wrapper'
import { images } from '../../constants';
import './Header.scss'

const scaleVariants = {
    whileInView: {
        scale: [0, 1],
        opacity: [0, 1],
        transition: {
            duration: 1, 
            ease: 'easeInOut'
        }
    }
}

const Header = () => {
    return (

       
        <div className='app__header'>

            <div className='app__header-container'>

                <div className='app__header-container__item1'>
<h2 className='head-text'>Welcome!<br/><br/> My name is <span>Garreth.</span> <br/><br/> I am a  <span>Frontend Developer. </span><br/><br/> With <span>4 years</span> experience.
        </h2>
                </div>

                <div className='app__header-container__item2'>
                    <img src={images.newphoto} alt='gary' />
                </div>

            </div>

        </div>
        

        // <div className='app__header app__flex'>
        //     <motion.div
        //     whileInView={{ x: [-100, 0], opacity: [0, 1]}}
        //     transition={{ duration: 0.5 }}
        //     className='app__header-info'
        //     >
        //         <div className='app__header-badge' >
        //             <div className='badge-cmp app__flex'>
        //                 <span>👋</span>
        //                 <div style={{ marginLeft: 20 }}>
        //                     <p className='p-text'>Hello, I am</p>
        //                     <h1 className='head-text'>Micael</h1>
        //                 </div>
        //             </div>

        //             <div className='tag-cmp app__flex'>
        //                     <p className='p-text'>Web Developer</p>
        //                     <p className='p-text'>Freelancer</p>
        //             </div>
        //         </div>
        //     </motion.div>

        //     <motion.div
        //     whileInView={{ opacity: [0, 1]}}
        //     transition={{ duration: 0.5, delayChildren: 0.5 }}
        //     className='app__header-img'
        //     >
        //         <img src={images.profile} alt='profile_bg' />
        //         <motion.img
        //         whileInView={{ opacity: [0, 1]}}
        //         transition={{ duration: 1, ease: 'easeInOut' }}
        //         src={images.circle}
        //         alt='profile_circle'
        //         className='overlay_circle'
        //         />
        //     </motion.div>


        //     <motion.div
        //     variant={scaleVariants}
        //     whileInView={scaleVariants.whileInView}
        //     className='app__header-circles'
        //     >
        //         {[images.flutter, images.redux, images.sass].map((circle, index) => (
        //             <div className='circle_cmp app__flex' key={`circle-${index}`} >
        //                 <img src={circle} alt='circle' />
        //             </div>
        //         ))}
        //     </motion.div>

        // </div>
    )
}

export default AppWrap(Header, 'home')
