import React from 'react'
import { BsGithub, BsInstagram, BsLinkedin } from 'react-icons/bs';
// import { FaFacebookF } from 'react-icons/fa'
// import { AiOutlineLinkedin } from 'react-icons/ai'

const SocialMedia = () => {
    return (
        <div className='app__social'>
            <div>
                <a href='https://github.com/GarrethHynes?tab=overview&from=2022-02-01&to=2022-02-28' target='_blank' >
                    <BsGithub />
                </a>
            </div>
            <div>
                <a href='https://www.instagram.com/gazhynes6/' target='_blank'>
                    <BsInstagram />
                </a>
                
            </div>
            <div>
                <a href='https://www.linkedin.com/in/garreth-hynes-84a825197/' target='_blank'>
                    <BsLinkedin />
                </a>
            </div>
        </div>
    )
}

export default SocialMedia
